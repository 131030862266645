import { createRecordByEnv } from "@karrotmarket-com/env";
import { dedent } from "ts-dedent";

/**
 * Carotene 에서 제공하는 속성은 사용하기 전에 명시적인 초기화가 필요해요.
 * 페이지 루트 요소에 data-carotene 어트리뷰트를 지정하고, 사용자가
 * 선호하는 컬러 스킴에 따라 data-carotene-color-mode 어트리뷰트를 지정해요.
 * 지정한 어트리뷰트를 통해 Carotene 컬러 토큰은 라이트/다크모드 전환을 해요.
 * 아래 스크립트는 이러한 전환은 위해 prefers-color-scheme 속성을 listen하면서,
 * 해당 필드를 교체하는 JavaScript Snippet이에요.
 */
export const CAROTENE_SCRIPT = dedent`
  (() => {
    document.documentElement.dataset.carotene = "";
    var d = window.matchMedia("(prefers-color-scheme: dark)"),
      a = () => {
        document.documentElement.dataset.caroteneColorMode = d.matches
          ? "dark"
          : "light";
      };
    "addEventListener" in d
      ? d.addEventListener("change", a)
      : "addListener" in d && d.addListener(a),
      a();
  })();
`;

export const APPSFLYER_SMART_BANNER_SCRIPT = dedent`
  !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "a5ca9e0a-c2fd-4afb-b855-f1deea3ce5d7"}});
  AF('banners', 'showBanner')
`;

export const PRIVACY_POLICY = {
  ca: "https://ca.karrotmarket.com/policy/privacy",
  us: "https://us.karrotmarket.com/policy/privacy",
  uk: "https://uk.karrotmarket.com/policy/privacy",
  jp: "https://jp.karrotmarket.com/policy/privacy",
  kr: "https://privacy.daangn.com/",
};

export const APP_SCHEME = {
  main: "karrot://tab/home",
};

export const APP_DOWNLOAD_URL = {
  GooglePlay:
    "https://play.google.com/store/apps/details?id=com.towneers.www&hl=ko",
  AppStore:
    "https://apps.apple.com/kr/app/%EB%8B%B9%EA%B7%BC%EB%A7%88%EC%BC%93-%EC%A4%91%EA%B3%A0%EA%B1%B0%EB%9E%98-%ED%95%84%EC%88%98%EC%95%B1/id1018769995",
};

export const NAVER_SITE_VERIFICATION_CONTENT =
  "92caf15a36e20dd7883c22230a35492c8b7ef804";

export const FB_APP_ID = "1463621440622064";

export const GTAG_ID = "GTM-NVJRT2M";

/**
 * 한국 이외의 글로벌 리전(CA, US, UK, JP) 전용 onelink
 */
export const GL_APPSFLYER_LINK = {
  Mobile: {
    Feed: "https://karrot.onelink.me/gNx7/4bed3b30?af_dp=towneers://open?from=website&deep_link_value=towneers://open?from=website",
    ArticleDetail: (articleId: string) =>
      `https://karrot.onelink.me/gNx7/bzrphfuc?af_dp=${encodeURIComponent(
        `karrot://articles/${articleId}?from=website`,
      )}&deep_link_value=${encodeURIComponent(
        `karrot://articles/${articleId}?from=website`,
      )}`,
  },
  Web: {
    Feed: "https://karrot.onelink.me/gNx7/t1iccvrq?deep_link_value=towneers://open?from=website",
    ArticleDetail: (articleId: string) =>
      `https://karrot.onelink.me/gNx7/f4098dd3?deep_link_value=karrot://articles/${articleId}?from=website`,
  },
};

export const DEFAULT_IMAGE_URL = {
  localProfile:
    "https://dnvefa72aowie.cloudfront.net/origin/etc/202402/2d023585efe8fad401729578a2cef344929e9f8df03c2740c7c8b7b1dc90999d.png",
};

/**
 * Sanity 트래픽 요금 절약을 위한 Cloudflare Worker Proxy
 */
export const SANITY_CDN_BASE_URL = "https://cdn.sanity.io/images/ym8073e8";

export const SANITY_CDN_CF_PROXY_BASE_URL =
  "https://karrotmarket-com-sanity-cdn.krrt.io";

export const MODAL_TRANSITION_DURATION = 400;

export const KR_MAIN_FEED_ONELINK =
  "https://daangn.onelink.me/oWdR/1f9a32c9?af_dp=karrot://open?from=website&deep_link_value=karrot://open?from=website";

export const SITE_NAME = {
  kr: "당근",
  ca: "Karrot",
  uk: "Karrot",
  us: "Karrot",
  jp: "Karrot",
};

export const BIG_PICTURE_ORIGIN = "https://dtxw8q4qct0d4.cloudfront.net";

export const KARROT_SCHEME = createRecordByEnv({
  alpha: "karrot.alpha://",
  production: "karrot://",
});

/**
 * 비즈프로필 단골 맺기 엔드포인트
 */
export const BP_CLIENT_ENDPOINT = createRecordByEnv({
  alpha: "https://alpha.bizpop-client.krrt.io/",
  production: "https://bizpop-client.krrt.io/",
});

/**
 * 모임 단지 커뮤니티 엔드포인트
 */
export const COMMUNITY_APT_ENDPOINT = createRecordByEnv({
  alpha: "https://community-web-apartment.alpha.kr.karrotmarket.com",
  production: "https://community-web-apartment.kr.karrotmarket.com",
});

/**
 * 모임(group) 앱 엔드포인트
 */
export const GROUP_APP_ENDPOINT = createRecordByEnv({
  alpha: "https://community-web-group.alpha.karrotwebview.com",
  production: "https://community-web-group.karrotwebview.com",
});

/**
 * Sentry DSN
 */
export const SENTRY_DSN =
  "https://6516b498149442e094c9fff2b63a8ec5@o24217.ingest.us.sentry.io/4505514179231744";

export const BUY_SELL_SCHEME_PREFIX = createRecordByEnv({
  alpha: "karrot.alpha://articles/",
  production: "karrot://articles/",
});

export const CAR_KARROTWEBVIEW_ENDPOINT = createRecordByEnv({
  alpha: "https://car.alpha.karrotwebview.com",
  production: "https://car.karrotwebview.com",
});

export const JOB_WRITE_APP_SCHEME = createRecordByEnv({
  alpha:
    "karrot.alpha://minikarrot/router?remote=https%3A%2F%2Fjobs.alpha.karrotwebview.com%2Fbiz%2Fjob-post%2Fcreate%3Freferrer%3Dpermalink&navbar=false&scrollable=false",
  production:
    "karrot://minikarrot/router?remote=https%3A%2F%2Fjobs.karrotwebview.com%2Fbiz%2Fjob-post%2Fcreate%3Freferrer%3Dpermalink&navbar=false&scrollable=false",
});

export const LOCAL_PROFILE_STAR_COLOR = "#FF6F0F";
